
import MFrame, { DelMessage, RouteListItem } from '@/components/frame/Frame.vue';
export default {
  components: {
    'm-frame': MFrame,
  },
  setup() {
    const menuList: RouteListItem[] = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'user',
        title: '商户管理',
      },
      {
        path: 'feed',
        title: 'Feed管理',
      },
      {
        path: 'lexicon',
        title: '词库管理',
      },
      {
        path: 'audiences',
        title: '受众管理',
      },
    ];

    const messageList: DelMessage[] = [
      { text: '删除本应用并不会影响购物商城的任何数据' },
      { text: '删除本应用仅会删除本应用上的功能以及数据' },
      {
        text: '删除本应用会删除所有已绑定账号，如需再次绑定，则需在criteo手动解绑账号',
      },
    ];

    return {
      menuList,
      messageList,
    };
  },
};
